import React from "react";
import "../../styles/homepage.css"

export default function BannerComponent() {
    return (
        <section className="banner-image">
            <div className="col-1"></div>
            <div className="col-5 banner-content">
                <h1>
                    ALIMENTOS, MEDICAMENTOS Y ACCESORIO MÉDICO.
                </h1>
                <br />
                <p>
                    Somos una <strong>empresa de distribución</strong> de productos y servicios dedicada a satisfacer las necesidades de los profesionales de la Medicina Veterinaria, tiendas de mascotas y estéticas caninas.
                </p>
            </div>
        </section>
    );
}