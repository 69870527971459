import React, { useState } from "react";
import sendEmail from "../../utils/emailSender";

export default function ContactUsComponent() {

    const [customer, setCustomer] = useState({
        name: "",
        lastName: "",
        email: "",
        message: ""
    });

    const handleInputChange = (event) => {
        const { value, name } = event.target;

        setCustomer(prevData => {
            return {
                ...prevData,
                [name]: value
            };
        });
    };

    const handleSubmit = (event) => {
        //Stop form submitting.
        event.preventDefault();

        //If true reset all form.
        if(sendEmail(customer)){
            setCustomer({
                name: "",
                lastName: "",
                email: "",
                message: ""
            });
        }
    };

    return (
        <section className="p-3 my-5">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg d-flex justify-content-center align-items-center flex-column pb-5">
                    {/*
                    <div>
                        <p>
                            ¡Descubre todo lo que en Invett junto con marcas asociadas tenemos para ti!
                        </p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link className="invett-button p-3" to="noticias">SABER MÁS</Link>
                    </div>
                    */}
                    <img className="image w-75 pb-4" src="/assets/images/handsome-young-male-veterinarian-smiling-joyfully-sitting-face-face-with-beautiful-healthy-dog-2.jpg" alt="handsome-young-male-veterinarian-smiling-joyfully-sitting-face-face-with-beautiful-healthy-dog-2.jpg"></img>
                    <div className="text-center pb-4">
                        Visita el nuevo <a className="text-link" href="https://tibetmx.com/dev2021/" target="blank">Sitio Web de TIBET</a> Accesorios y navega entre todos los productos y categorías del catálogo en línea, descubre nuevas formas de buscar por característica de producto o descárgalo en un archivo PDF.
                    </div>
                    <div className="d-flex justify-content-center">
                        <a className="invett-button p-3" href="http://tibetmx.com/catalogo-tibet.pdf" target="blank">Ver catálogo</a>
                    </div>
                </div>
                <div className="col-lg d-flex justify-content-center flex-column align-items-center box py-5 m-5">
                    <div>
                        <h3 className="text-center">¿CÓMO PODEMOS AYUDARLE?</h3>
                    </div>
                    <form className="p-3" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm mb-3">
                                <label htmlFor="nameField" className="form-label">Nombre *</label>
                                <input type="text" className="form-control" id="nameField" name="name" value={customer.name} onChange={handleInputChange} placeholder="Escribe tu nombre" required/>
                            </div>
                            <div className="col-sm mb-3">
                                <label htmlFor="lastNameField" className="form-label">Apellido *</label>
                                <input type="text" className="form-control" id="lastNameField" name="lastName" value={customer.lastName} onChange={handleInputChange} placeholder="Escribe tu apellido" required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="emailField" className="form-label">Correo *</label>
                                <input type="email" className="form-control" id="emailField" name="email" value={customer.email} onChange={handleInputChange} placeholder="Escribe tu correo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="commentField" className="form-label">Comentario o mensaje *</label>
                                <textarea className="form-control" id="commentField" name="message" value={customer.message} onChange={handleInputChange} placeholder="Comentario o mensaje..." rows="5" required></textarea>
                            </div>
                        </div>
                        <div className="container d-flex justify-content-center mt-2">
                            <button type="submit" className="invett-button px-5 py-3">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}