import React from "react";
import "../../styles/homepage.css"

function ServiceContainer(props) {
    return (
        <div className="col text-center service-container m-3">
            <i className={props.icon}></i>
            <h3>{props.title}</h3>
            <p>{props.text}</p>
        </div>
    );
}

export default function ServiceInformationComponent() {
    return (
        <div>
            <div className="row orders">
                <div className="col pb-4">
                    <h3> ¿YA ERES DISTRIBUIDOR?</h3>
                    <p>Accede a tus pedidos por medio de esta web</p>
                </div>
                <div className="col d-flex justify-content-center align-items-center">
                    <a href="https://tienda.invett.com/" >PEDIDOS</a>
                </div>
            </div>

            <div className="container p-5 mt-3">
                <div className="row d-flex justify-content-center">
                    <ServiceContainer
                        icon="fa-solid fa-arrow-right-arrow-left"
                        title="CANALES"
                        text="Veterinarios, tiendas de mascotas, 
                        estéticas caninas, criaderos y entrenadores."
                    />
                    <ServiceContainer
                        icon="fa-regular fa-rectangle-list"
                        title="PRODUCTOS"
                        text="Alimentos, accesorios, biológicos y medicamentos."
                    />
                    <ServiceContainer
                        icon="fa-solid fa-handshake"
                        title="SERVICIOS"
                        text="Asesoría de negocio, instrumentos y aparatos cremación: Pet Forever."
                    />
                    <ServiceContainer
                        icon="fa-regular fa-map"
                        title="DISTRIBUCIÓN"
                        text="Amplia cobertura en territorio nacional: Pacifico norte, occidente, centro y CDMX."
                    />
                </div>
            </div>
        </div>
    );
}