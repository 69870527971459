import React from "react";
import ItemList from "./ItemList";

export default function CanineBeautyEquipmentComponent(){
    return (
        <section>
            <div className="row d-flex align-items-center">
                <div className="container">
                    <p className="title">Equipos para estética</p>
                </div>
                <div className="col-md p-5">
                    <img className="image" src="/assets/images/veterinarian-trimming-yorkshire-terrier-with-hair-clipper-veterinary-clinic-female-groomer-haircut-yorkshire-terrier-table-grooming-beauty-salon-dogs.jpg" alt="veterinarian-trimming-yorkshire-terrier-with-hair-clipper-veterinary-clinic-female-groomer-haircut-yorkshire-terrier-table-grooming-beauty-salon-dogs.jpg"></img>
                </div>
                <div className="col-md">
                    <p>Productos de aseo y estética para las mascotas.</p>
                    <div className="row pt-3">
                        <ItemList text="Shampoo" />
                        <ItemList text="Secadoras" />
                        <ItemList text="Maquinas Andis" />
                        <ItemList text="Tijeras" />
                        <ItemList text="Rasuradoras" />
                        <ItemList text="Perfume" />
                    </div>
                </div>
            </div>
        </section>
    );
}