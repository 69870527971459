import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer.css"

export default function FooterComponent() {

    const handleClick = () => {
        window.scrollTo(0,0);
    };

    return (
        <footer>
            <div className="row py-5 text-center">
                <div className="col-sm">
                    <a href="https://g.page/Invett?share" target="blank">
                        <i className="fa-solid fa-location-dot location-icon"></i>
                    </a>
                    <p><strong>Av. Las Torres #1636</strong> <br /> Zapopan, Jal.</p>
                    <p><strong>C. Mexicaltzingo #1422</strong> <br />Guadalajara, Jal.<br />CDMX</p>
                    <p><strong>Avenida de los Insurgentes #1674</strong> <br />Tijuana B.C</p>
                </div>
                <div className="col-sm">
                    <a href="tel:3338265160" className="text-decoration-none">
                        <i className="fa-solid fa-phone phone-icon"></i>
                        <p><strong>Tel. 33 3826 5160</strong></p>
                    </a>
                    <p>Tel. 55 6235 4867</p>
                    <p>Tel. 66 4978 9577</p>
                    <a href="mailto:ventas@invett.com" className="text-decoration-none">
                        <p><strong>ventas@invett.com</strong></p>
                    </a>
                </div>
                <div className="col-sm">
                    <i className="fa-regular fa-clock clock-icon"></i>
                    <p><strong>Lunes a Viernes:</strong></p>
                    <p>8:30 am – 2:00 pm <br /> 4:00 pm – 6:30 pm</p>
                    <p><strong>Sábados:</strong></p>
                    <p>8:30 am – 2:00 pm</p>
                </div>
                <div className="col-sm d-flex flex-column align-items-center">
                    <i className="fa-solid fa-share social-icons"></i>
                    <p><strong>¡Síguenos! en:</strong></p>
                    <div>
                        <a href="https://www.facebook.com/Invett.Veterinarios/" target="blank">
                            <i className="fa-brands fa-facebook facebook-icon"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/invett/" target="blank">
                            <i className="fa-brands fa-linkedin-in linked-in-icon"></i>
                        </a>
                    </div>
                    <div className="d-flex justify-content-center" onClick={handleClick}>
                        <Link className="invett-button p-2 my-2" to="aviso-de-privacidad">Aviso de privacidad</Link>
                    </div>
                </div>
            </div>

            <hr />

            <div className="d-flex align-items-center">
                <p>&#169; 2022 Invett.</p>
            </div>

            <i className="fa-solid fa-arrow-up go-up" onClick={handleClick}></i>
            <a href="https://web.whatsapp.com/send?phone=+5213331675832" target="blank">
                <i className="fa-brands fa-whatsapp floating-whatsapp"></i>
            </a>
        </footer>
    );
}