import React from "react";

export default function ItemList(props) {
    return (
        <div className="col-6 mb-2">
            <p>
                <i className={'fa-solid fa-circle circle-icon px-3'}/>
                <strong>{props.text}</strong>
            </p>
        </div>
    );
}