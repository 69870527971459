import Swal from 'sweetalert2';

const token = "f1b55b77-4cfa-46f1-84b4-5705df4fef11";
const fromEmail = "contacto@invett.com";
const emailSubject = "Consulta Invett:";


export default function sendEmail(customer){
    return window.Email.send({
        SecureToken : token,
        To : 'ventas@invett.com',
        From : fromEmail,
        Subject : `${emailSubject} ${customer.name} ${customer.lastName}`,
        Body : `Correo: 
        ${customer.email} 
        ${(customer.phoneNumber !== undefined) ? ` || Teléfono: ${customer.phoneNumber}` : ''} 
        ${(customer.subject !== undefined) ? ` || Asunto: ${customer.subject}` : ''} 
        -> Mensaje: ${customer.message}` 
    }).then(emailSuccess(customer), emailFailure);
}

export function emailSuccess(customer){
    Swal.fire(
        `Gracias por tus comentarios ${customer.name}!`,
        'Muy pronto nos pondremos en contacto contigo!',
        'success'
    )
    return true;
}

export function emailFailure(){
    Swal.fire(
        'Algo ha salido mal...',
        'Intenta de nuevo mas tarde!',
        'error'
    )
    return false;
}