import React from "react";
import ItemList from "./ItemList";

export default function DrugsComponent() {
    return (
        <section>
            <div className="row d-flex align-items-center">
                <div className="container">
                    <p className="title">Medicamentos</p>
                </div>
                <div className="col-md p-5">
                    <img className="image" src="/assets/images/specialist-looks-through-microscope-clinic.jpg" alt="specialist-looks-through-microscope-clinic.jpg"></img>
                </div>
                <div className="col-md">
                    <p>Marcas líderes en Farmacología y salud animal. Todo lo que necesite un MVZ para mantener la salud de los animales de compañía.</p>
                    <div className="row pt-3">
                        <ItemList text="Boehringer" />
                        <ItemList text="Pisa" />
                        <ItemList text="Bionote" />
                        <ItemList text="Zoetis" />
                        <ItemList text="Vetoquinol" />
                        <ItemList text="Ceva" />
                        <ItemList text="MSD" />
                        <ItemList text="Holland" />
                        <ItemList text="Holliday" />
                        <ItemList text="Bayer" />
                        <ItemList text="Lapisa" />
                        <ItemList text="Labyes" />
                        <ItemList text="Mederilab" />
                        <ItemList text="Petspharma" />
                    </div>
                </div>
            </div>
        </section>
    );
}