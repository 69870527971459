import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";

//SharedComponents
import NavBarComponent from "./NavBarComponent";
import FooterComponent from "./FooterComponent";

//Pages
import HomePage from "../pages/HomePage/HomePage";
import CoveragePage from "../pages/CoveragePage";
import ProductPage from "../pages/ProductsPage/ProductsPage";
import ContactPage from "../pages/ContactPage";
import PromotionsPage from "../pages/PromotionsPage";
import PrivacyNotice from "../pages/PrivacyNotice";
import NotFoundPage from "../pages/NotFoundPage";

function App() {
  return (
    <BrowserRouter>

      <NavBarComponent />

      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/distribucion" element={<CoveragePage />}></Route>
        <Route path="/productos" element={<ProductPage />}></Route>
        <Route path="/contacto" element={<ContactPage />}></Route>
        {/* <Route path="/promociones" element={<PromotionsPage />}></Route> */}
        <Route path="/aviso-de-privacidad" element={<PrivacyNotice />}></Route>
        <Route path="*" element={<NotFoundPage />}></Route>
      </Routes>

      <FooterComponent />
      
    </BrowserRouter>
  );
}

export default App;
