import React from "react";
import "../styles/privacynotice.css"

export default function PrivacyNotice() {
    return (
        <section>
            <div className="banner-container"></div>
            <div className="privacy-text px-5 pb-5 mx-5">
                <h2>INVETT SA DE CV</h2>
                <h3>Fecha de publicación 07/09/2019 Versión No.2</h3>
                <p>
                    En Invett se tienen estrictas medidas y políticas de seguridad para salvaguardar la confidencialidad de los datos a los que tiene acceso. Al proporcionarnos usted sus datos personales (los “Datos”) se entiende que algunos de los datos personales pudieran considerarse como sensibles. Por lo anterior dicho y en términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “Ley”) se hace entrega del Aviso de Privacidad a que se refieren los artículos 2, 16 y 17 de la Ley, a continuación se proporciona la información correspondiente:
                </p>
                <ol>
                    <li>Información del responsable que recaba los Datos: INVETT SA DE CV</li>
                    <li>Domicilio: MEXICALTZINGO 1422 COLONIA MODERNA CP 44190 GUADALAJARA JALISCO</li>
                    <li>La finalidad del tratamiento de los Datos que se recaban es:</li>
                </ol>
                <p>
                    INVETT recibe, utiliza y comparte información identificable (“datos personales”) sobre médicos veterinarios y otros profesionales del cuidado de la salud veterinaria, proveedores, clientes, y/o público en general, con apego a las leyes aplicables y, si corresponde, según lo autorizado por las autoridades gubernamentales aplicables, en relación con nuestras operaciones comerciales diarias, que pueden incluir de forma enunciativa mas no limitativa lo siguiente:
                </p>
                <ul>
                    <li>Gestión del sistema administrativo de la empresa , facturas, pedidos , devoluciones.</li>
                    <li>O informes de quejas de productos y eventos adversos.</li>
                    <li>O comunicar información sobre nuestros productos a los profesionales de la salud veterinaria, mediante nuestros representantes, nuestros sitios web, correo electrónico, centros de llamadas, correo postal y otros canales, incluidas comunicaciones de promoción y otras de comercialización.</li>
                    <li>O simposios, conferencias y otros programas educativos y científicos.</li>
                    <li>O investigación de mercados.</li>
                    <li>O proporcionar acceso a nuestros servicios y llevar el seguimiento de nuestras interacciones con los profesionales de la salud veterinaria por Internet o fuera del mismo.</li>
                    <li>O información general sobre programas de apoyo a Médicos Veterinarios y profesionales de la salud veterinaria.</li>
                    <li>O según lo requerido o autorizado expresamente por las leyes aplicables al negocio de INVETT de manera local.</li>
                </ul>
                <p>
                    Cuando las leyes locales requieran que usted proporcione consentimiento específico para la recopilación, el uso, transferencia y/o la divulgación de información personal para algunos de estos programas, es posible que se le solicite que proporcione su consentimiento por cualquiera de los medios previamente mencionados, o mediante otros medios permitidos en términos de la ley. Excepto cuando se nos requiera por ley o reglamentación utilizar o divulgar información personal y/o sensible para ciertos fines. 4. Mediante la aceptación del presente aviso de privacidad, Usted autoriza expresamente a INVETT para que use y transfiera los Datos, a favor de cualquier tercero, en el entendido de que (i) algunos de los datos personales pudieran considerarse como sensibles y (ii) el tercero receptor asumirá las mismas obligaciones que corresponden al responsable que transfirió los datos, de conformidad con lo dispuesto en el artículo 36 de la Ley. Lo anterior, en el entendido de que algunos de los datos personales pudieran considerarse como sensibles. Lo anterior, en el entendido de que el tercero receptor no podrá a su vez transferir los datos que le fueron transferidos a favor de ningún tercero, salvo a INVETT de regreso y /o a el encargado de su resguardo. 5. Usted puede en términos de lo establecido en el artículo 28 de la “Ley”, de dirigirse a INVETT para ejercer los siguientes derechos respecto de los “Datos”: (i) Tener acceso a sus Datos; (ii) rectificar los mismos; (iii) cancelarlos; (iv) oponerse a un uso distinto al autorizado.
                </p>
                <p>
                    Para lo anterior, le pedimos dirigir una comunicación a INVETT, que contenga la siguiente información:
                </p>
                <ul>
                    <li>O Su nombre, su domicilio o medio que nos permita contactarlo.</li>
                    <li>O Identificación oficial (en su caso, documento que acredite la representación del titular de los datos personales.</li>
                    <li>O descripción precisa del tipo de acción que desea se tome respecto de sus Datos. Adjuntar la documentación que sustente la petición, así como los documentos que acrediten su personalidad, en su caso.</li>
                </ul>
                <p>
                    Cuando Usted se dirija a INVETT, le pedimos atentamente lo haga al siguiente departamento, persona y dirección.
                </p>
                <p>
                    INVETT SA DE CV<br/>
                    Responsable del Departamento de Datos Personales.<br/>
                    Dirección: Mexicaltzingo No. 1422 Colonia Moderna Guadalajara Jalisco CP 44190.<br/>
                    En caso de existir cualquier duda respecto del manejo de sus datos personales, puede dirigirse a la siguiente dirección de correo electrónico: ventas@invett.com o al siguiente sitio en Internet: www.invett.com
                </p>
            </div>
        </section>
    );
}