import React from "react";
import FoodComponent from "./FoodComponent";
import DrugsComponent from "./DrugsComponent";
import AccessoriesComponent from "./AccessoriesComponent";
import CanineBeautyEquipmentComponent from "./CanineBeautyEquipmentComponent";
import "../../styles/productspage.css"

export default function ProductsPage() {
    return (
        <section id="products-page">
            <FoodComponent />
            <DrugsComponent />
            <AccessoriesComponent />
            <CanineBeautyEquipmentComponent />
        </section>
    );
}