import React from "react";
import ItemList from "./ItemList";

export default function AccessoriesComponent() {
    return (
        <section>
            <div className="row d-flex align-items-center">
                <div className="container">
                    <p className="title">Accesorios</p>
                </div>
                <div className="col-md p-5">
                    <p>Venta de accesorios y juguetes para mascotas. Una <strong>gran variedad</strong> de productos con diferentes materiales y estilos, pensado para los compradores que buscan un buen diseño, algo profesional, para entrenamiento o económico. Hecha un vistazo a nuestro catálogo.</p>
                    <div className="row pt-5">
                        <ItemList text="Pecheras" />
                        <ItemList text="Juguetes" />
                        <ItemList text="Correas" />
                        <ItemList text="Camas" />
                        <ItemList text="Cadenas" />
                        <ItemList text="Suéteres" />
                    </div>
                    <div className="d-flex justify-content-center">
                        <a className="invett-button p-3" href="http://tibetmx.com/catalogo-tibet.pdf" target="blank">Ver catálogo</a>
                    </div>
                </div>
                <div className="col-md">
                    <img className="image" src="/assets/images/handsome-young-male-veterinarian-smiling-joyfully-sitting-face-face-with-beautiful-healthy-dog-2.jpg" alt="handsome-young-male-veterinarian-smiling-joyfully-sitting-face-face-with-beautiful-healthy-dog-2.jpg"></img>
                </div>
            </div>
        </section>
    );
}