import React, { useState } from "react";
import "../styles/contactpage.css";
import sendEmail from "../utils/emailSender";

export default function ContactPage() {

    const [customer, setCustomer] = useState({
        name: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const handleInputChange = (event) => {
        const { value, name } = event.target;

        setCustomer(prevData => {
            return {
                ...prevData,
                [name]: value
            };
        });
    };

    const handleSubmit = (event) => {
        //Stop form submitting.
        event.preventDefault();

        //If true reset all form.
        if(sendEmail(customer)){
            setCustomer({
                name: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                subject: '',
                message: ''
            });
        }
    };

    return (
        <section className="p-3 my-5">
            <div className="container d-flex justify-content-center">
                <h1>¡Estamos para atenderte!</h1>
            </div>

            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg d-flex justify-content-center align-items-center flex-column pb-5">
                    <div className="p-5">
                        <p>Para pedidos, consultas o comentarios, por favor contáctenos le atenderemos con gusto y a la brevedad.</p>
                    </div>

                    <div className="d-flex justify-content-center flex-column contact-icon-container py-3">
                        <a href="tel:3338265160" className="text-center">
                            <i className="fa-solid fa-phone icon-color"></i>
                        </a>
                        <p><strong>33 3826 5160</strong></p>
                    </div>
                    <div className="d-flex justify-content-center flex-column contact-icon-container py-3">
                        <a href="tel:3338265160" className="text-center">
                            <i className="fa-solid fa-envelope icon-color"></i>
                        </a>
                        <p><strong>ventas@invett.com</strong></p>
                    </div>

                </div>
                <div className="col-lg d-flex justify-content-center flex-column align-items-center box py-5 m-5">
                    <div>
                        <h2 className="text-center">Contáctanos</h2>
                    </div>
                    <form className="p-3" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm mb-3">
                                <label htmlFor="nameField" className="form-label">Nombre *</label>
                                <input type="text" className="form-control" id="nameField" name="name" value={customer.name} onChange={handleInputChange} placeholder="Escribe tu nombre" />
                            </div>
                            <div className="col-sm mb-3">
                                <label htmlFor="lastNameField" className="form-label">Apellido *</label>
                                <input type="text" className="form-control" id="lastNameField" name="lastName" value={customer.lastName} onChange={handleInputChange} placeholder="Escribe tu apellido" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="emailField" className="form-label">Correo *</label>
                                <input type="email" className="form-control" id="emailField" name="email" value={customer.email} onChange={handleInputChange} placeholder="Escribe tu correo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="phoneNumberField" className="form-label">Teléfono de contacto *</label>
                                <input type="text" className="form-control" id="phoneNumberField" name="phoneNumber" value={customer.phoneNumber} onChange={handleInputChange} placeholder="Escribe tu número de teléfono" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="subjectField" className="form-label">Asunto *</label>
                                <input type="text" className="form-control" id="subjectField" name="subject" value={customer.subject} onChange={handleInputChange} placeholder="Escribe el asunto" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="commentField" className="form-label">Comentario o mensaje *</label>
                                <textarea className="form-control" id="commentField" name="message" value={customer.message} onChange={handleInputChange} placeholder="Comentario o mensaje..." rows="5"></textarea>
                            </div>
                        </div>
                        <div className="container d-flex justify-content-center mt-2">
                            <button type="submit" className="invett-button px-5 py-3">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}