import React from "react";
import { Link } from "react-router-dom";

export default function ExclusiveSale() {

    const handleClick = () => document.querySelector('#exclusive-sale-close-modal-button').click();

    return (
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Aviso</h5>
                        <button type="button" id="exclusive-sale-close-modal-button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body d-flex justify-content-center mb-5">
                        <div className="exclusive-sale-content">
                            <div className="row">
                                <div className="col-dm">
                                    <img className="exclusive-sale-banner" src="/assets/images/Baner web.jpg" alt="venta-exclusiva" />
                                </div>
                                <div className="col-dm pt-3 text-center">
                                    <p className="text-information">Para mas información acerca de estos productos:</p>
                                    <div className="row mt-5">
                                        <div className="col-sm mb-5">
                                            <a className="exclusive-sale-button p-3" href="https://ar.zoetis.com/diagnostic/index.aspx" target="blank">Visita este sitio</a>
                                        </div>
                                        <div className="col-sm">
                                            <Link className="invett-button p-3" onClick={handleClick} to="/contacto">O contáctanos</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}