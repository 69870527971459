import React, { useEffect } from "react";

//Components
import BannerComponent from "./BannerComponent";
import ServiceInformationComponent from "./ServiceInformationcomponent";
import AlliedBrandsComponent from "./AlliedBrandsComponent";
import ContactUsComponent from "./ContactUsComponent";

//Temporal component
import ExclusiveSale from "./ExclusiveSale";

export default function HomePage() {

    useEffect(() => {
        document.querySelector("#exclusive-sale-modal-trigger").click();
    });

    return (
        <section id="home-page">
            <input id="exclusive-sale-modal-trigger" type="hidden" data-bs-toggle="modal" data-bs-target="#staticBackdrop"></input>
            <ExclusiveSale />
            <BannerComponent />
            <ServiceInformationComponent />
            <AlliedBrandsComponent />
            <ContactUsComponent />
        </section>
    );
}