import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../styles/navbar.css"

function NavBarAndMenuLogo(props) {
    return (
        <Link to="/" className={props.size} onClick={props.click}>
            <img className="main-logo" src="/assets/images/Logo-invett.jpg" alt="Logo-invett.jpg" />
        </Link>
    );
}

function NavBarLink(props) {
    return (
        <NavLink to={props.to} end={props.end} className={({ isActive }) => isActive ? 'nav-link invett-link-active' : 'nav-link invett-link'} onClick={props.click}>
            {props.text}
        </NavLink>
    );
}

export default function NavBarComponent() {

    const handleClick = () => {
        window.scrollTo(0,0);

        document.querySelector('#mobile-menu-close-button').click();
    };

    return (
        <>{/* while the promotions section is available, navbar-expand-LG, otherwise navbar-expand-md and change css classes.*/}
            <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white shadow">
                <div className="container-fluid">
                    
                    <NavBarAndMenuLogo size="logo-container" click={handleClick}/>

                    <button className="responsive-menu-button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions">
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="container d-flex justify-content-center align-items-center">
                            <NavBarLink to="/" text="Inicio" end={true} click={handleClick}/>
                            <NavBarLink to="/distribucion" text="Distribución" end={false} click={handleClick} />
                            <NavBarLink to="/productos" text="Productos" end={false} click={handleClick} />
                            <NavBarLink to="/contacto" text="Contacto" end={false} click={handleClick} />
                            {/* <NavBarLink to="/promociones" text="Promociones" end={false} click={handleClick} /> */}
                            <a className="nav-link invett-link" href="https://tienda.invett.com/" target="blank">Pedidos</a>
                        </div>
                    </div>

                </div>
            </nav>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div className="offcanvas-header">

                    <NavBarAndMenuLogo size="col-8 offcanvas-title" />
                    <button type="button" id="mobile-menu-close-button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>

                </div>
                <div className="offcanvas-body">
                    <NavBarLink to="/" text="Inicio" end={true} click={handleClick}/>
                    <NavBarLink to="/distribucion" text="Distribución" end={false} click={handleClick}/>
                    <NavBarLink to="/productos" text="Productos" end={false} click={handleClick}/>
                    <NavBarLink to="/contacto" text="Contacto" end={false} click={handleClick}/>
                    {/* <NavBarLink to="/promociones" text="Promociones" end={false} click={handleClick} /> */}
                    <a className="nav-link invett-link" href="https://tienda.invett.com/" target="blank">Pedidos</a>
                </div>
            </div>
        </>
    );
}