import React from "react";
import ItemList from "./ItemList";

export default function FoodComponent() {
    return (
        <section>
            <div className="row d-flex align-items-center">
                <div className="container">
                    <p className="title">Alimento</p>
                </div>
                <div className="col-md p-5">
                    <p>Contamos con marcas premium en alimento para perros y gatos, con líneas de prescripción y mantenimiento.</p>
                    <div className="row pt-3">
                        <ItemList text="Proplan" />
                        <ItemList text="Mira" />
                        <ItemList text="K9" />
                        <ItemList text="Iron Dog" />
                        <ItemList text="Dog Chow" />
                    </div>
                </div>
                <div className="col-md">
                    <img className="image" src="/assets/images/alimento.jpg" alt="alimento.jpg"></img>
                </div>
            </div>
        </section>
    );
}