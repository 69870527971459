import React from "react";
import "../styles/coverage.css"

export default function CoveragePage() {
    return (
        <section>
            <div className="row p-5 m-5 text">
                <div className="col-md">
                    <h1 className="py-3 title">Cobertura</h1>
                    <p>Contamos con una amplia cobertura en territorio nacional.</p>
                    <div className="row pt-3">
                        <div className="container mb-3">
                            <p>
                                <i className="fa-solid fa-circle circle-icon px-3" />
                                <strong>Norte: </strong>
                                Baja california, Tijuana y Los Cabos.
                            </p>
                        </div>
                        <div className="container mb-3">
                            <p>
                                <i className="fa-solid fa-circle circle-icon px-3" />
                                <strong>Occidente: </strong>
                                Jalisco, Tepic, Morelia, Querétaro.
                            </p>
                        </div>
                        <div className="container mb-3">
                            <p>
                                <i className="fa-solid fa-circle circle-icon px-3" />
                                <strong>Centro: </strong>
                                Ciudad de México.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md d-flex align-items-center">
                    <img className="coverage-map-image" src="/assets/images/Mexico-Invett-map.png" alt="Mexico-invett-map.png"></img>
                </div>
            </div>
        </section>
    );
}