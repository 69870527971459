import React from "react";

function CarouselImage(props) {
    return (
        <img src={props.src} alt={props.alt} />
    );
}

export default function AlliedBrandsComponent() {

    const assets = "/assets/images";

    return (
        <section>
            <div className="row allied-brands d-flex align-items-center">
                <div className="col-md">
                    <img className="veterinary-image" src="/assets/images/veterinary-doctor.jpg" alt="veterinary-doctor.jpg"></img>
                </div>
                <div className="col-md d-flex justify-content-center flex-column align-items-center">
                    <h3>MARCAS ALIADAS</h3>
                    <p>Ponemos a su alcance las Marcas Líderes en el mercado de Laboratorios y Alimento Premium, así como un gran surtido calidad en accesorios para mascotas y equipo para tu negocio.</p>
                    <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <CarouselImage src={`${assets}/alpha_chem-carousel.jpg`} alt="alpha_chem-carousel.jpg" />
                                <CarouselImage src={`${assets}/andis-carousel.jpg`} alt="andis-carousel.jpg" />
                                <CarouselImage src={`${assets}/azoo-carousel.jpg`} alt="azoo-carousel.jpg" />
                                <CarouselImage src={`${assets}/bimeda-carousel.jpg`} alt="bimeda-carousel.jpg" />
                            </div>
                            <div className="carousel-item">
                                <CarouselImage src={`${assets}/bionote-carousel.jpg`} alt="bionote-carousel.jpg" />
                                <CarouselImage src={`${assets}/boehringer-carousel.jpg`} alt="boehringer-carousel.jpg" />
                                <CarouselImage src={`${assets}/ceva-carousel.jpg`} alt="ceva-carousel.jpg" />
                                <CarouselImage src={`${assets}/elanco-carousel.png`} alt="elanco-carousel.png" />
                            </div>
                            <div className="carousel-item">
                                <CarouselImage src={`${assets}/esteripharma-carousel.jpg`} alt="esteripharma-carousel.jpg" />
                                <CarouselImage src={`${assets}/Hollan-carousel.jpg`} alt="Hollan-carousel.jpg" />
                                <CarouselImage src={`${assets}/Holliday-carousel.jpg`} alt="Holliday-carousel.jpg" />
                                <CarouselImage src={`${assets}/iron_dog-carousel.jpg`} alt="iron_dog-carousel.jpg" />
                            </div>
                            <div className="carousel-item">
                                <CarouselImage src={`${assets}/k9_master_dog-carousel.jpg`} alt="k9_master_dog-carousel.jpg" />
                                <CarouselImage src={`${assets}/kiron-carousel.jpg`} alt="kiron-carousel.jpg" />
                                <CarouselImage src={`${assets}/Kruuse-carousel.jpg`} alt="Kruuse-carousel.jpg" />
                                <CarouselImage src={`${assets}/Labyes-carousel.jpg`} alt="Labyes-carousel.jpg" />
                            </div>
                            <div className="carousel-item">
                                <CarouselImage src={`${assets}/lapisa-carousel.jpg`} alt="lapisa-carousel.jpg" />
                                <CarouselImage src={`${assets}/mederi_lab-carousel.jpg`} alt="mederi_lab-carousel.jpg" />
                                <CarouselImage src={`${assets}/msd-2-carousel.jpg`} alt="msd-2-carousel.jpg" />
                                <CarouselImage src={`${assets}/ourofino-2-carousel.jpg`} alt="ourofino-2-carousel.jpg" />
                            </div>
                            <div className="carousel-item">
                                <CarouselImage src={`${assets}/pets_pharma-carousel.jpg`} alt="pets_pharma-carousel.jpg" />
                                <CarouselImage src={`${assets}/pisa-carousel.jpg`} alt="pisa-carousel.jpg" />
                                <CarouselImage src={`${assets}/purina_pro_plan-carousel.jpg`} alt="purina_pro_plan-carousel.jpg" />
                                <CarouselImage src={`${assets}/spectrum-carousel.jpg`} alt="spectrum-carousel.jpg" />
                            </div>
                            <div className="carousel-item">
                                <CarouselImage src={`${assets}/super_cria-carousel.jpg`} alt="super_cria-carousel.jpg" />
                                <CarouselImage src={`${assets}/tibet-carousel.jpg`} alt="tibet-carousel.jpg" />
                                <CarouselImage src={`${assets}/vetoquinol-carousel.jpg`} alt="vetoquinol-carousel.jpg" />
                                <CarouselImage src={`${assets}/virbac-carousel.jpg`} alt="virbac-carousel.jpg" />
                                <CarouselImage src={`${assets}/zoetis-carousel.jpg`} alt="zoetis-carousel.jpg" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row engagement mt-5">
                <p>
                    Estamos comprometidos a llevarles a nuestros clientes <strong>productos y servicios de la más alta calidad</strong>, con prontitud, participando en su desarrollo y con el compromiso de estar cada vez más cerca de ellos.
                </p>
            </div>
        </section>
    );
}